import React, { useContext } from 'react';
import { Context } from '../Context';
import Logo from '../common/Logo';
import LinkButton from '../common/LinkButton';

const TutorialPage = () => {
  const context = useContext(Context);

  return (
    <main id="tutorial-page" className="page background-primary">
      <div className="container">
        <Logo />
        <div id="text" className="color-secondary">
          <h2>It's simple to play</h2>
          <p>You have 10 seconds to answer each question</p>
          <p>The more you get right, the more points you score</p>
          <p>
            Whoever scores the most points each day at the 2024 IoD Conference
            will win a handpicked assortment of premium red wines!
          </p>
          <p>Play as many times you want</p>
        </div>

        <LinkButton
          text="Cool, let's play"
          callback={() => context.setCurrentPage('register')}
          className="color-green-light"
        />
      </div>
    </main>
  );
};

export default TutorialPage;
