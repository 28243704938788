import React from "react";

const Tick = ({ className, ...props }) => (
  <svg
    width={22}
    height={21}
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={className}
  >
    <path
      d="M3 12L9 18L19 3"
      strokeWidth={5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);


export default Tick;
