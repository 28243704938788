import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../Context';
import LoadingPage from './LoadingPage';
import Logo from '../common/Logo';
import LinkButton from '../common/LinkButton';

const LeaderboardPage = () => {
  const context = useContext(Context);
  const [leaderboard, setLeaderboard] = useState(null);
  const [error, setError] = useState(null);

  const userLeaderboard = leaderboard
    ? leaderboard.find((s) => s.isCurrentUser)
    : null;
  const top5 = leaderboard ? leaderboard.slice(0, 5) : null;
  const isTop5 = top5 ? top5.find((s) => s.isCurrentUser) : null;

  const attemptFetchLeaderboard = async () => {
    try {
      const leaderboard = await context.getLeaderboard();
      setLeaderboard(leaderboard);
    } catch (e) {
      console.error(e);
      setError(e);
    }
  };

  useEffect(() => {
    attemptFetchLeaderboard();

    const interval = setInterval(() => {
      attemptFetchLeaderboard();
    }, 60000); // 60000 ms = 1 minute

    return () => clearInterval(interval);
  }, []);

  if (error) return <LoadingPage error={true} />;
  if (!leaderboard) return <LoadingPage />;

  return (
    <main id="leaderboard-page" className="page background-primary">
      <div className="container color-secondary">
        <Logo />
        <h2>Leaderboard 🏆</h2>

        <div id="leaderboard">
          {top5.map((s, i) => (
            <div key={i}>
              <p>{i + 1}</p>
              <p>{s.name}</p>
              <p>{s.score}</p>
            </div>
          ))}
          {!isTop5 && userLeaderboard && (
            <div id="user-submission">
              <p>{leaderboard.indexOf(userLeaderboard) + 1}</p>
              <p>{userLeaderboard.name}</p>
              <p>{userLeaderboard.score}</p>
            </div>
          )}
        </div>

        <p>
          We’ll email you who the winners are at 4.01pm at the end of each day
          of the IoD Conference.
        </p>

        {context.userData.score !== undefined &&
        context.userData.score !== null ? (
          <LinkButton
            text="Go back"
            callback={() => context.setCurrentPage('results')}
            back={true}
          />
        ) : (
          <LinkButton
            text="Go back"
            callback={() => context.setCurrentPage('home')}
            back={true}
          />
        )}
      </div>
    </main>
  );
};


export default LeaderboardPage;
