import React from "react";

const LoadingPage = ({ error }) => {
  return (
    <main id='loading-page' className='page background-primary'>
      <div className='color-secondary'>
        {
          error ? <h2>An error occured...</h2>
            : <h2>Loading...</h2>
        }
      </div>
    </main>
  );
}


export default LoadingPage;
