import React from 'react';
import OptionCircle from '../images/OptionCircle';
import Cross from '../images/Cross';
import Tick from '../images/Tick';

const QuizOptionIcon = ({ show }) => {
  if (show === 'correct' || show === 'missed') {
    return <Tick />;
  }
  if (show === 'incorrect') {
    return <Cross />;
  }
  if (show === 'timed-out') {
    return null;
  }
  return <OptionCircle />;
};

const QuizOption = ({ option, show, callback }) => {
  return (
    <div
      className={
        'quiz-option'
        + (show === 'correct' ? '--correct' : '')
        + (show === 'incorrect' ? '--incorrect' : '')
        + (show === 'missed' ? '--missed' : '')
        + (show === 'timed-out' ? '--timed-out' : '')
      }
      onClick={callback}>
      <QuizOptionIcon show={show} />
      <p>{option}</p>
    </div>
  );
};


export default QuizOption;
