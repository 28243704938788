import React from 'react';
import LinkArrow from '../images/LinkArrow';

const LinkButton = ({ className, callback, text, back = false, ...props }) => (
  <div
    className={
      `link-button`
      + (back ? '--back' : '')
      + (className ? ` ${className}` : '')
    }
    onClick={callback}
    {...props}
  >
    {back ? (
      <>
        <LinkArrow />
        <p>{text}</p>
      </>
    ) : (
      <>
        <p>{text}</p>
        <LinkArrow />
      </>
    )}

  </div >
);


export default LinkButton;
