import React, { createContext, useState } from 'react';
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL;
const persistSubmissions = false;
export const Context = createContext();

export const ContextProvider = ({ children }) => {

  const prevSubs = persistSubmissions
    ? JSON.parse(localStorage.getItem('prevSubmissionIds')) || []
    : [];
  const [currentPage, setCurrentPage] = useState('home');
  const [userData, setUserData] = useState({});
  const [submissionId, setSubmissionId] = useState(prevSubs[prevSubs.length - 1] || null);
  const [prevSubmissionIds, setPrevSubmissionIds] = useState(prevSubs || []);

  const register = (firstName, lastName, email, optIn) => {
    setUserData({ firstName, lastName, email, optIn });
  };

  const getQuestions = async () => {
    const response = await axios.get(API_URL + '/questions');
    return response.data;
  };

  const setScore = async (score) => {
    setUserData({ ...userData, score });
  }

  const submitScore = async () => {
    if (userData.score == null) throw new Error('Score not set!');
    if (submissionId) return;
    const { data: { id } } = await axios.post(
      API_URL + '/submission', {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      optIn: userData.optIn,
      score: userData.score
    });
    const submissionIds = [...prevSubmissionIds, id]
    setSubmissionId(id);
    setPrevSubmissionIds(submissionIds);
    persistSubmissions && localStorage.setItem('prevSubmissionIds', JSON.stringify(submissionIds));
  };

  const getLeaderboard = async () => {
    if (!submissionId && userData.score !== undefined && userData.score !== null) {
      await submitScore();
    }
    const { data: { leaderboard } } = await axios.get(API_URL + '/leaderboard');
    const userSubmissions = leaderboard.filter(s => prevSubmissionIds.includes(s.id));
    const bestScoreSubmission = userSubmissions.reduce((a, b) => a.score > b.score ? a : b, { score: 0 });
    return leaderboard.map((s => ({ ...s, isCurrentUser: s.id === bestScoreSubmission.id })));
  };

  const playAgain = () => {
    setCurrentPage('quiz');
    setUserData({ ...userData, score: null });
    setSubmissionId(null);
  };

  return (
    <Context.Provider value={{
      currentPage,
      setCurrentPage,
      register,
      userData,
      prevSubmissionIds,
      getQuestions,
      getLeaderboard,
      setScore,
      submitScore,
      playAgain
    }}>
      {children}
    </Context.Provider>
  );
};
