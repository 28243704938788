import React, { useContext, useEffect, useState } from "react";
import { Context } from "../Context";
import LoadingPage from "./LoadingPage";
import QuizOption from "../common/QuizOption";
import CircleTimer from "../common/CircleTimer";
import LinkButton from "../common/LinkButton";
import { IoDHeadline } from "../images/headers/IoDHeadline";
import { NZOCHeadline } from "../images/headers/NZOCHeadline";
import { MercuryHeadline } from "../images/headers/MercuryHeadline";
import { BurgerKingHeadline } from "../images/headers/BurgerKingHeadline";
import { FullersHeadline } from "../images/headers/FullersHeadline";
import { ConsumerNZHeadline } from "../images/headers/ConsumerNZHeadline";
import { TSBBankHeadline } from "../images/headers/TSBBankHeadline";
import { NowNZHeadline } from "../images/headers/NowNZHeadline";
import { CMTHeadline } from "../images/headers/CMTHeadline";

const TOTAL_TIME = 10;
let timer;

const QuestionImage = ({ category }) => {
  let headline = {
    'IoD Conference 2024': <IoDHeadline />,
    'NZ Team': <NZOCHeadline />,
    'Mercury': <MercuryHeadline />,
    'Burger King NZ': <BurgerKingHeadline />,
    'Fullers': <FullersHeadline />,
    'Consumer NZ':<ConsumerNZHeadline />,
    'TSB': <TSBBankHeadline />,
    'NOW': <NowNZHeadline />,
    'Colin McCahon': <CMTHeadline />,
  }[category];

  return headline
};

const QuizPage = () => {
  const context = useContext(Context);
  const [questions, setQuestions] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [error, setError] = useState(null);
  const [time, setTime] = useState(TOTAL_TIME);
  const [score, setScore] = useState(0);
  const [lost, setLost] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState(null);
  const revealed = selectedChoice != null || lost;

  const attemptFetchQuestions = async () => {
    try {
      const questions = await context.getQuestions();
      setQuestions(questions.questions);

    } catch (e) {
      setError(e);
    }
  };

  const handleNextQuestion = () => {
    if (questionIndex >= questions.length - 1) return;
    setQuestionIndex(prevIndex => prevIndex + 1);
    setTime(TOTAL_TIME);
    setSelectedChoice(null);
    document.scrollingElement.scrollTop = 0;
  };

  const handleAnswer = (answer) => {
    if (selectedChoice) return;
    if (lost) return;
    setTime(0)
    setSelectedChoice(answer);
    if (answer === questions[questionIndex].correctChoice) {
      setScore(s => s + 1);
    } else {
      setLost(true);
    }
  };

  const handleShowResults = () => {
    context.setScore(score);
    context.setCurrentPage('results');
  };

  useEffect(() => {
    if (questions) return;
    attemptFetchQuestions();
  }, []);

  useEffect(() => {
    if (!questions) return;
    if (error) return;

    timer = setInterval(() => {
      setTime(prevTime => prevTime > 0 ? prevTime - 0.05 : 0);
    }, 50);

    return () => clearInterval(timer);
  }, [questions]);

  useEffect(() => {
    if (selectedChoice) return;
    if (time <= 0) setLost(true);

  }, [time]);

  useEffect(() => {
    if (!questions) return;
    if (error) return;

  }, [questions, error]);

  if (error) return <LoadingPage error={true} />;
  if (!questions) return <LoadingPage />;

  return (
    <main id='quiz-page' className={'page background-secondary' + (!time ? ' timed-out' : '')}>
      <div id='banner'>
        <CircleTimer time={time} totalTime={TOTAL_TIME} visible={!!time} />
        <p><span>{score}</span> correct</p>
      </div>
      <QuestionImage category={questions[questionIndex].category} />
      <div className='container'>
        <h3>{questions[questionIndex].question}</h3>
        <div id='options'>

          {[
            questions[questionIndex].choice1,
            questions[questionIndex].choice2,
            questions[questionIndex].choice3,
            questions[questionIndex].choice4
          ].map((choice, i) => (
            <QuizOption
              key={i}
              option={choice}
              callback={() => handleAnswer(choice)}
              show={
                revealed ? (
                  choice === questions[questionIndex].correctChoice
                    ? (
                      choice === selectedChoice
                        ? (
                          // selected the correct choice
                          'correct'
                        ) : (
                          // didnt select this correct choice
                          'missed'
                        )
                    ) : (
                      choice === selectedChoice
                        ? (
                          // selected this incorrect choice
                          'incorrect'
                        ) : (
                          // didnt select this incorrect choice
                          (!time ? 'timed-out' : 'default')
                        )
                    )
                ) : (!time ? 'timed-out' : 'default')
              }
            />
          ))}
        </div>
      </div>
      <div id="quiz-page__footer">
        {!lost && selectedChoice && (questionIndex !== questions.length - 1) && (
          <LinkButton
            callback={handleNextQuestion}
            text='Next question'
            className='color-blue'
          />
        )}

        {(lost || (selectedChoice && questionIndex === questions.length - 1)) && (
          <LinkButton
            callback={handleShowResults}
            text='See results'
            className='color-blue'
          />
        )}
      </div>
    </main>
  );
};


export default QuizPage;
