import React, { useContext, useEffect } from 'react';
import { ContextProvider, Context } from './Context';
import HomePage from './pages/HomePage';
import TutorialPage from './pages/TutorialPage';
import RegisterPage from './pages/RegisterPage';
import QuizPage from './pages/QuizPage';
import ResultsPage from './pages/ResultsPage';
import LeaderboardPage from './pages/LeaderboardPage';
import '../styles/App.scss';


const App = () => {
  useEffect(() => {
    const setVh = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };
    window.addEventListener('resize', setVh);
    setVh();

    return () => window.removeEventListener('resize', setVh);
  }, []);

  return (
    <ContextProvider>
      <Routing />
    </ContextProvider>
  );
};

const Routing = () => {
  const { currentPage } = useContext(Context);

  switch (currentPage) {
    case 'home':
      return <HomePage />;
    case 'tutorial':
      return <TutorialPage />;
    case 'register':
      return <RegisterPage />;
    case 'quiz':
      return <QuizPage />;
    case 'results':
      return <ResultsPage />;
    case 'leaderboard':
      return <LeaderboardPage />;
    default:
      return <HomePage />;
  }
};


export default App;
