export const TSBBankHeadline = () => {
  return (
    <header className={`header tsb`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="393"
        height="150"
        fill="none"
        viewBox="0 0 393 150"
      >
        <path fill="#1A124D" d="M0 0h393v150H0z" />
        <path
          fill="#007C4E"
          d="M152.781 73.88V51C143.976 64.171 129.586 73.302 113 75c16.586 1.697 30.976 10.827 39.781 24V76.118c-9.979.004-19.728-.486-29.223-1.118 9.495-.633 19.244-1.125 29.223-1.12Z"
        />
        <path
          fill="#00B18F"
          d="M152.781 73.88V51c8.806 13.171 23.194 22.302 39.781 24-16.587 1.697-30.975 10.827-39.781 24V76.118c9.979.004 19.728-.486 29.223-1.118-9.495-.633-19.244-1.125-29.223-1.12Z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M222.834 83.185c2.331 1.202 5.584 2.128 9.323 2.128 2.864 0 5.584-.556 5.584-3.146 0-2.507-2.787-3.308-6.036-4.244-4.542-1.307-9.988-2.874-9.988-9.722 0-7.768 7.963-9.896 14.761-9.896 3.255 0 6.459.463 8.74 1.204l.013 6.658c-2.331-1.017-5.547-1.48-8.121-1.48-2.282 0-6.069.186-6.069 3.006 0 2.096 2.578 2.786 5.665 3.613 4.611 1.234 10.358 2.772 10.358 9.75 0 8.372-7.38 10.639-14.907 10.639-3.156 0-6.535-.463-9.547-1.388l.224-7.122Zm-21.92-17.943h-8.352v-6.381h25.735v6.381h-8.352V91.14h-9.031V65.242Zm66.346-6.381h-15.586V91.14h14.275c6.07 0 13.207-1.62 13.207-9.343 0-3.93-2.331-6.242-6.846-7.12v-.093c3.787-1.202 5.972-3.468 5.972-7.537 0-5.55-4.71-8.186-11.022-8.186Zm-4.03 12.763h-2.526v-6.659h2.623c3.011 0 5.924.277 5.924 3.237 0 2.728-3.059 3.422-6.021 3.422Zm.145 13.411h-2.671v-7.584h2.768c3.108 0 6.653.323 6.653 3.838 0 3.283-3.448 3.746-6.75 3.746Z"
          clipRule="evenodd"
        />
      </svg>
    </header>
  );
};
