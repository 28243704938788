import React, { useEffect, useRef } from "react";

const CircleTimer = ({ time, totalTime, visible = true }) => {
  const circleRef = useRef(null);
  const radius = 45;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progress = time / totalTime;
    const dashoffset = circumference * (1 - progress);

    circleRef.current.style.strokeDasharray = `${circumference} ${circumference}`;
    circleRef.current.style.strokeDashoffset = dashoffset;
  }, [time]);

  return (
    <div className='circle-timer' style={{ opacity: visible ? 1 : 0 }}>
      <svg viewBox={`0 0 ${radius * 2} ${radius * 2}`}>
        <circle
          ref={circleRef}
          cx={radius}
          cy={radius}
          r={radius * 0.9}
          strokeWidth='10'
          fill='none'
        />
      </svg>
      <p>{Math.round(time)}</p>
    </div>
  );
}

export default CircleTimer;