import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../Context';
import LoadingPage from './LoadingPage';
import Logo from '../common/Logo';
import LinkButton from '../common/LinkButton';

const ResultsPage = () => {
  const context = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const attemptSubmitScore = async () => {
    try {
      await context.submitScore();
      setLoading(false);
    } catch (e) {
      setError(e);
      console.error(e);
    }
  };

  useEffect(() => {
    attemptSubmitScore();
  }, []);

  if (error) return <LoadingPage error={true} />;
  if (loading) return <LoadingPage />;

  let message;
  const score = context.userData.score;
  if (score >= 0 && score <= 3) {
    message = 'Better luck next time!';
  } else if (score >= 4 && score <= 20) {
    message = 'Great job! 🥳';
  } else if (score >= 21 && score <= 100) {
    message = "You're on fire! 🔥";
  }

  return (
    <main id="results-page" className="page background-primary">
      <div className="container color-secondary">
        <Logo />
        <h2>{message}</h2>
        <div className="color-blue-light" id="result">
          <h1>{context.userData.score}</h1>
          <h2>correct</h2>
        </div>

        <div className="color-secondary" id="links">
          <LinkButton text="Play again" callback={context.playAgain} />
          <LinkButton
            text="View the leaderboard"
            callback={() => context.setCurrentPage('leaderboard')}
          />
        </div>
      </div>
    </main>
  );
};

export default ResultsPage;
