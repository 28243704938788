import React from "react";
import LogoImage from "../images/Logo";

const Logo = ({ className }) => {
  return <LogoImage
    className={`logo color-secondary ${className}`}
  />;
}


export default Logo;
