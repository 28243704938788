import React from 'react';

const LinkArrow = ({ className, ...props }) => (
  <svg
    width={13}
    height={13}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 -0.00421143H11H0V1.99579H9.59L0 11.5858L1.41421 13L11 3.41421V12.9958H13V-0.00421143Z"
    />
  </svg>
);


export default LinkArrow;
