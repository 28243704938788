import React, { useContext } from "react";
import { Context } from "../Context";
import Logo from "../common/Logo";
import LinkButton from "../common/LinkButton";

const HomePage = () => {
  const context = useContext(Context);

  return (
    <main id="home-page" className="page background-primary">
      <div className="container">
        <Logo />

        <div id="text">
          <h1 className="color-secondary">Kia ora 👋</h1>
          <h2 className="color-secondary">
            Welcome to the{" "}
            <span className="color-green-light">Catch Design Quiz</span>
          </h2>
          <h3 className="color-secondary">
            Play to win a handpicked assortment of premium red wines!
          </h3>
        </div>

        <div className="color-green-light" id="links">
          <LinkButton
            callback={() => context.setCurrentPage("tutorial")}
            text="Play the quiz" />
          <LinkButton
            className={`text-sm`}
            callback={() => context.setCurrentPage("leaderboard")}
            text="View the leaderboard" />
        </div>

        <a href="https://www.catchdesign.co.nz/" className="color-secondary">
          catchdesign.co.nz
        </a>
      </div>
    </main>
  );
};


export default HomePage;
