import React, { useContext, useState } from 'react';
import { Context } from '../Context';
import Logo from '../common/Logo';
import LinkButton from '../common/LinkButton';

const RegisterPage = () => {
  const context = useContext(Context);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    optIn: false
  });

  const [emailError, setEmailError] = useState(false);

  /**
   * Validate whether an email is in a valid format.
   *
   * @param {string} email - The email to validate.
   * @returns {boolean} - True if the email is valid, false otherwise.
   */
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };

  const isFormValid = () => {
    return (
      formData.firstName.trim() !== '' &&
      formData.lastName.trim() !== '' &&
      validateEmail(formData.email)
    );
  };

  const submitForm = () => {
    if (isFormValid()) {
      context.register(
        formData.firstName,
        formData.lastName,
        formData.email,
        formData.optIn
      );
      context.setCurrentPage('quiz');
    } else {
      if (!validateEmail(formData.email)) {
        setEmailError(true);
      }
    }
  };

  return (
    <main id="register-page" className="page background-primary">
      <div className="container color-secondary">
        <Logo />
        <div>
          <h2>Before we start</h2>
          <p>We just need to know a few details</p>
        </div>

        <div id="form">
          <input
            type="text"
            placeholder="Your first name"
            onChange={(e) =>
              setFormData({ ...formData, firstName: e.target.value })
            }
          />
          <input
            type="text"
            placeholder="Your last name"
            onChange={(e) =>
              setFormData({ ...formData, lastName: e.target.value })
            }
          />
          <input
            type="email"
            placeholder="Your email address"
            className={emailError ? 'error' : ''}
            onChange={(e) => {
              setEmailError(false);
              setFormData({ ...formData, email: e.target.value });
            }}
          />
          {emailError && (
            <p className="error">Please enter a valid email address</p>
          )}

          <p>
            We’re collecting this info for the purposes of this quiz, so we can
            notify the winners. This data will be deleted once the 2024 IoD
            Conference has finished and not collected or retained by anyone at
            Catch Design.
          </p>
          <div className={`checkbox`}>
            <input
              type="checkbox"
              id="opt-in"
              onChange={(e) =>
                setFormData({ ...formData, optIn: e.target.checked })
              }
            />
            <label htmlFor="opt-in">
              Fancy a one-time email with some info about Catch? (We promise
              it’s just one!)
            </label>
          </div>
        </div>
        <LinkButton
          text="Start the quiz"
          callback={submitForm}
          className="color-green-light"
        />
      </div>
    </main>
  );
};

export default RegisterPage;
