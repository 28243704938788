export const NowNZHeadline = () => {
  return (
    <header className={`header now`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="393"
        height="150"
        fill="none"
        viewBox="0 0 393 150"
      >
        <path fill="#fff" d="M0 0h393v150H0z" />
        <path
          fill="#333"
          d="M159.374 95.583a6.557 6.557 0 0 1-4.671-1.932 6.557 6.557 0 0 1-1.932-4.67V73.049c0-4.23-1.744-6.382-5.178-6.382-4.45 0-5.388 3.467-5.388 6.382v15.689c0 3.698-2.915 6.746-6.503 6.79h-.1A6.606 6.606 0 0 1 129 88.925V61.577c0-3.699 2.926-6.746 6.503-6.79h.099c2.661 0 5.057 1.623 6.084 4.03 2.915-2.97 6.271-4.417 10.213-4.417 8.689 0 14.088 5.852 14.088 15.281v19.1c0 3.7-2.915 6.747-6.503 6.79h-.099l-.011.012ZM248.286 95.252c-3.136 0-5.543-1.888-6.58-5.167l-5.123-16.263-5.179 16.274c-1.049 3.279-3.444 5.167-6.58 5.167h-.353c-3.158 0-5.543-1.91-6.724-5.366l-9.175-26.487c-.166-.497-.541-1.744-.541-2.882 0-2.815 2.241-5.719 5.995-5.719 2.882 0 5.079 1.733 6.028 4.748l4.914 16.307 5.034-16.241c.928-3.014 3.335-4.891 6.305-4.891h.629c2.97 0 5.388 1.877 6.304 4.902l5.101 16.34 5.057-16.484c.938-2.98 3.102-4.692 5.962-4.692 3.279 0 5.863 2.54 5.863 5.785 0 .85-.254 1.955-.387 2.419l-.022.055-9.462 26.84c-1.17 3.445-3.566 5.355-6.713 5.355h-.353Z"
        />
        <path
          fill="#6C0"
          d="M187.937 51c-14.033.276-24.003 10.478-23.738 24.345.276 13.978 10.654 23.915 24.676 23.65 14.033-.276 24.092-10.644 23.816-24.588-.265-13.868-10.699-23.672-24.754-23.396"
        />
      </svg>
    </header>
  );
};
