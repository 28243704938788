import React from 'react';

const Logo = ({ className, ...props }) => (
  <svg
    className={className}
    viewBox='0 0 88 40'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.0134 26.469C15.7509 28.7463 18.3681 30.4003 22.4 30.4003C26.4319 30.4003 29.0491 28.7463 30.7866 26.469C32.6435 24.0352 33.6 20.7073 33.6 17.6003H43.2C43.2 22.4407 41.7601 27.9128 38.4188 32.2921C34.9581 36.828 29.5754 40.0002 22.4 40.0002C15.2246 40.0002 9.84188 36.828 6.38116 32.2921C3.03985 27.9128 1.60001 22.4407 1.60001 17.6003H11.2C11.2 20.7073 12.1565 24.0352 14.0134 26.469Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 6.4C0 2.86768 2.86774 0 6.4 0C9.93226 0 12.8 2.86768 12.8 6.4C12.8 9.93232 9.93226 12.8 6.4 12.8C2.86774 12.8 0 9.93232 0 6.4Z'

    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.1812 10.908C54.6419 6.37211 60.0247 3.19986 67.2 3.19986C74.3753 3.19986 79.7581 6.37211 83.2188 10.908C86.5601 15.2873 88 20.7594 88 25.5999H78.4C78.4 22.4928 77.4435 19.1649 75.5866 16.7311C73.8491 14.4539 71.2319 12.7999 67.2 12.7999C63.1681 12.7999 60.5509 14.4539 58.8134 16.7311C56.9565 19.1649 56 22.4928 56 25.5999H46.4C46.4 20.7594 47.8399 15.2873 51.1812 10.908Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.6 39.1999H46.4V31.1999H65.6V39.1999Z'
    />
  </svg>
);


export default Logo;
